<template>
  <div class="offices">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.offices.offices.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.offices.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
      >
        <template v-slot:[`item.multilanguage`]="item">
          {{ item.item.multilanguage ? "SI" : "NO" }}
        </template>
        <template v-slot:[`item.status`]="item">
          <v-chip
            class="ma-2"
            :color="getStatusColor(item.item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item.item)"
                class="px-1"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.item.status == 'ACTIVE' ? 'red' : 'success'"
                dark
                v-bind="attrs"
                v-on="on"
                class="px-1"
                @click="changeStatus(item.item)"
              >
                {{
                  item.item.status == "ACTIVE" ? "mdi-lock" : "mdi-lock-open-variant"
                }}
              </v-icon>
            </template>
            <span>
              {{ item.item.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR" }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <!-- Button active dialog -->
    <v-btn class="mx-2" fab bottom right fixed color="secondary" @click="open">
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <!-- Create or edit dialog -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ currentOffice ? "Editar" : "Crear" }} sede
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4">
          <v-form ref="formOffice" v-model="validForm" lazy-validation>
            <v-text-field
              v-model="model.name"
              :rules="rules.required"
              label="Nombre"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.country"
              :rules="rules.required"
              label="Pais"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.city"
              :rules="rules.required"
              label="Ciudad"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.address"
              :rules="rules.required"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.phone"
              :rules="rules.required"
              label="Teléfono"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.prefix"
              :rules="rules.required"
              label="Prefijo"
              required
            ></v-text-field>
            <v-text-field
              v-model="model.legal_id"
              :rules="rules.required"
              label="EIN"
              required
            ></v-text-field>
            <v-select
              :items="multilanguageItems"
              v-model="model.multilanguage"              
              label="Multilenguaje"
              required
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validForm"
            color="success"
            class="mr-4"
            small
            @click="save"
          >
            Guardar
          </v-btn>

          <v-btn color="error" text class="mr-4" small @click="clear">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- change status dialog -->
    <v-dialog v-model="dialogChangeStatus" width="500" persistent>
      <v-card>
        <v-card-text class="py-4" v-if="dialogChangeStatus">
          Seguro que desea
          <b>{{
            currentOffice.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR"
          }}</b>
          esta sede?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-4"
            @click="changeStatusConfirmation"
            small
          >
            Confirmar
          </v-btn>

          <v-btn color="error" text class="mr-4" @click="clear" small>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_OFFICES } from "@/store/offices";
import { mapGetters } from "vuex";
export default {
  name: "Offices",
  data: () => ({
    search: "",
    headers: [
      {
        text: "Nombre",
        align: "start",
        filterable: true,
        value: "name",
      },
      {
        text: "Pais",
        align: "start",
        filterable: true,
        value: "city",
      },
      {
        text: "Ciudad",
        align: "start",
        filterable: true,
        value: "country",
      },
      {
        text: "Dirección",
        align: "start",
        filterable: false,
        value: "address",
      },
      {
        text: "Teléfono",
        align: "start",
        filterable: false,
        value: "phone",
      },
      {
        text: "Multilenguaje",
        align: "start",
        filterable: true,
        value: "multilanguage",
      },
      {
        text: "Prefijo",
        align: "start",
        filterable: true,
        value: "prefix",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    dialog: false,
    dialogChangeStatus: false,
    validForm: true,
    currentOffice: null,
    model: {
      name: "",
      city: "",
      country: "",
      address: "",
      phone: "",
      legal_id: "",
      prefix: "",
      multilanguage: "",
    },
    rules: {
      required: [(v) => !!v || "EL campo es requerido"],      
    },
    multilanguageItems: [
      {
        text: "SI",
        value: 1,
      },
      {
        text: "NO",
        value: 0,
      },
    ],
  }),
  created() {
    this.$store.dispatch("getOffices");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_OFFICES) {
        this.pagination.current = state.offices.offices.current_page;
        this.pagination.total = state.offices.offices.last_page;
      }
    });
  },
  watch: {
    dialog: function () {
      if (this.dialog == false) this.clear();
    },
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor"]),
  },
  methods: {
    nextPage() {
      this.$store.dispatch("nextPageOffices", this.pagination.current);
    },
    save() {
      if (!this.$refs.formOffice.validate()) return;

      if (this.currentOffice) {
        this.$store.dispatch("updateOffice", {
          form: this.model,
          id: this.currentOffice.id,
        });
      } else {
        this.$store.dispatch("createOffice", this.model);
      }
      this.clear();
    },
    changeStatus(current) {
      this.currentOffice = current;
      this.dialogChangeStatus = true;
    },
    changeStatusConfirmation() {
      this.$store.dispatch("updateOffice", {
        form: {
          status: this.currentOffice.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        id: this.currentOffice.id,
      });
      this.clear();
    },
    open() {
      this.dialog = true;
    },
    edit(current) {
      this.currentOffice = current;
      this.model = {
        name: current.name,
        city: current.city,
        country: current.country,
        address: current.address,
        phone: current.phone,
        legal_id: current.legal_id,
        prefix: current.prefix,
        multilanguage: current.multilanguage,
      };
      this.open();
    },
    clear() {
      this.dialog = false;
      this.dialogChangeStatus = false;
      if (this.model.name !== "") this.$refs.formOffice.resetValidation();
      this.model = {
        name: "",
      };
      this.currentOffice = null;
    },
  },
};
</script>
<style scoped>
.offices {
  height: 100%;
}
</style>
